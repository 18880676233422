import { createAction } from '@reduxjs/toolkit';

export const SetCanvasSizeAction = createAction<[number, number]>('mapCommon/SetCanvasSizeAction');

export const SetViewportAction = createAction< { zoom: number, center?: [number, number] }>('mapCommon/SetViewportAction');

export const SetLatAction = createAction<string>('mapCommon/SetLatAction');

export const SetLngAction = createAction<string>('mapCommon/SetLngAction');

export const SetMarkerAction = createAction<[number, number]>('mapCommon/SetMarkerAction');
export const ClearMarkerAction = createAction('mapCommon/ClearMarkerAction');

export const ClearTileJsonAction = createAction<string>('mapCommon/ClearTileJsonAction');

export const SetLasBBoxAction = createAction<[number, number, number, number]|null>('mapCommon/SetLasBBoxAction');

export const SetMapStyleAction = createAction<MapStyleType>('mapCommon/SetMapStyleAction');

export const ResetProjectTifInfoAction = createAction('mapCommon/ResetProjectTifInfoAction');
export const ResetProjectLasInfoAction = createAction('mapCommon/ResetProjectLasInfoAction');

export const SetLargeTifCount = createAction<number>('mapCommon/SetLargeTifCount');
export const SetLargeTifAcknowledgedAction = createAction<boolean>('mapCommon/SetLargeTifAcknowledgedAction');
export const SetNewLargeTifAction = createAction('mapCommon/SetNewLargeTifAction');

export const GetTileJsonStartAction = createAction<TileJsonType>('mapCommon/GetTileJsonStartAction');
export const GetTileJsonStopAction = createAction<TileJsonType>('mapCommon/GetTileJsonStopAction');

export const GetVectorTileJsonAction = createAction<{ [key: string]: { [key: string]: IVectorTileJson; } }>('mapCommon/GetVectorTileJsonAction');
export const GetRasterTileJsonAction = createAction<{ [key: string]: IRasterTileJson }>('mapCommon/GetRasterTileJsonAction');

export const SetLowResCreatedAtAction = createAction<string>('mapCommon/SetLowResCreatedAtAction');
export const SetDelayInRasterTileLoadAction = createAction<boolean>('mapCommon/SetDelayInRasterTileLoadAction');
export const SetDelayInRasterTileLoadAcknowledgedAction = createAction<boolean>('mapCommon/SetDelayInRasterTileLoadAcknowledgedAction');

export const ChangeVersionAction = createAction('mapCommon/ChangeVersionAction');
export const ChangeRasterAction = createAction('mapCommon/ChangeRasterAction');

export const SetAllRasterTilesLoadedAction = createAction<boolean>('mapCommon/SetAllRasterTilesLoadedAction');
export const SetLasBBoxLoadingAction = createAction<boolean>('mapCommon/SetLasBBoxLoadingAction');

export const ZoomInAction = createAction('mapCommon/ZoomInAction');
export const ZoomOutAction = createAction('mapCommon/ZoomOutAction');

export const ResetLatAndLngAction = createAction('mapCommon/ResetLatAndLngAction');

export const ToggleOrthomosaicLayerAction = createAction('mapCommon/ToggleOrthomosaicLayerAction');
export const ToggleBasemapLayerAction = createAction('mapCommon/ToggleBasemapLayerAction');

export const ToggleCadDrawingsLayerAction = createAction<{ orderId: string, fileVersion: string }>('mapCommon/ToggleCadDrawingsLayerAction');

export const AddNewLayerToVTJAction = createAction<{ orderId: string, siteId: string, layer: IVectorLayer }>('mapCommon/AddNewLayerToVTJAction');

export const UpdateLayerVTJAction = createAction<{ orderId: string, siteId: string, layer: IVectorLayer }>('mapCommon/UpdateLayerVTJAction');

export const DeleteLayerFromVTJAction = createAction<{ orderId: string, siteId: string, layerId: Number }>('mapCommon/DeleteLayerFromVTJAction');

export const ToggleProjectLayerAction = createAction<{ orderId: string, id: string, fileVersion: string }>('mapCommon/ToggleProjectLayerAction');
export const ToggleAllProjectLayersAction = createAction<{ orderId: string, fileVersion: string, isAllVisible: boolean }>('mapCommon/ToggleAllProjectLayersAction');

export const LocationSearchStartAction = createAction('mapCommon/LocationSearchStartAction');
export const LocationSearchSuccessAction = createAction<IPlacesSearchFeature[]>('mapCommon/LocationSearchSuccessAction');

export const ClearSuggestionsAction = createAction('mapCommon/ClearSuggestionsAction');
export const SetScreenshotAction = createAction<string>('mapCommon/SetScreenshotAction');
export const ToggleNoneVectorTilesAction = createAction('mapCommon/ToggleNoneVectorTilesAction');

export const AdminToggleCadDropdownAction = createAction<{ orderId: string, fileVersion: string }>('mapCommon/AdminToggleCadDropdownAction');

export const SetDxfExtentAction = createAction<number[]>('mapCommon/SetDxfExtentAction');

export const SetLayerVisibleAction = createAction<any>('mapCommon/SetLayerVisibleAction');
