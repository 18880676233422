// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { Menu, MenuItem, Checkbox, InputBase, Divider, Typography, List, ListItemButton, ListItem, Dialog, DialogActions, DialogContent, ListItemSecondaryAction, Button, IconButton, DialogTitle, TextField, Icon, InputLabel, FormGroup } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import aiIcon from 'Assets/ai.png';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { ToggleAllProjectLayers, ToggleProjectLayer as ToggleProjectLayerAction } from 'Features/map/mapCommonThunk';
import {
  SetlayerSelected as SetlayerSelectedAction,
  EditLayerAttribute as EditLayerAttributeAction,
  DeleteLayer as DeleteLayerAction,
  Autosave as AutosaveAction,
} from 'Features/map/mapEditorThunk';
import { SetEditorModeAction, SetOrderSelectedAction } from 'Features/map/mapEditorActions';
import LinePropertiesFlyout from './LinePropertiesFlyout/index';
import stylesDef from './styles';
import { NewLayerDialog } from './NewLayerDialog';
import { ToggleAllProjectLayersAction } from 'Features/map/mapCommonActions';

moment.tz.setDefault('America/New_York');

interface ComponentProps {
  layers?: IVectorTileJson;
  expressAIInfo?: boolean;
  order: IOrder;
  siteId: string;
}

export const ProjectLayersList: React.FC<ComponentProps> = ({ order, layers, siteId, expressAIInfo }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();

  const fileVersions = useAppSelector((state) => state.order.fileVersions);
  const layerSelected = useAppSelector((state) => state.map.editor.present.layerSelected);
  const allLayersDeselected = layers.vector_layers.every((layer) => !layer.visible);

  const resources = useAppSelector((state) => state.auth.resources);
  const editorFunctions = 'editorFunctions' in resources;

  const [checked, setChecked] = useState(true);
  const [layerName, setLayerName] = useState(null);
  const [edit, setEdit] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showNewLayerDialog, setNewLayerDialog] = useState(false);
  const [newLayer, setNewLayer] = useState(false);
  const [mouseX, setMouseX] = useState(null);
  const [mouseY, setMouseY] = useState(null);
  const [deleteLayerId, setDeleteLayerId] = useState(null);

  const inputRef = useRef(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, layerId: number) => {
    setDeleteLayerId(layerId);
    event.preventDefault();
    setMouseX(event.clientX - 2);
    setMouseY(event.clientY - 4);
  };

  const handleClose = () => {
    setMouseX(null);
    setMouseY(null);
  };

  const handleDelete = async () => {
    handleClose();
    await dispatch(DeleteLayerAction(siteId, deleteLayerId));
  };

  useEffect(() => {
    setChecked(layers.visible);
  }, [layers.visible]);

  useEffect(() => {
    // When we create a new layer and the newly created layer is appended to the vector_layers array and it's name includes 'untitled-layer'
    // Set this newly created layer in edit mode
    if (newLayer && (layers && layers.vector_layers.length > 0 && layers.vector_layers[0].id === layerSelected) && layerSelected.includes('untitled-layer')) {
      const customName:string | null = layers.vector_layers.find((layer) => layer.id === layerSelected).custom_name;
      setLayerName(customName ? customName : layerSelected);
      setEdit(true);
      setNewLayer(false);
    }
  }, [layerSelected, newLayer]);

  return (
    <Box sx={styles.listWrapper}>
      <Box sx={styles.projectLayersText}>
        <Typography variant="h6" sx={styles.layersLabel}>
          Project Layers
        </Typography>
        {
          editorFunctions && layers.vector_layers.length !== 0 ? (
            <IconButton
              sx={styles.newLayerButton}
              onClick={() => setNewLayerDialog(true)} // onAddNewLayerClick}
              disabled={!editorFunctions}
            >
              <AddCircleOutline sx={styles.addIcon} />
            </IconButton>
          ) : <></>
        }
      </Box>
      <Box sx={styles.projectLayersText}>
        <Button variant="text" sx={styles.deselectButton} onClick={() => {
          dispatch(ToggleAllProjectLayersAction({orderId:order._id , fileVersion:fileVersions[order._id], isAllVisible: allLayersDeselected}));
          dispatch(SetlayerSelectedAction(null));
          dispatch(SetEditorModeAction(false));
        }}>
          {allLayersDeselected ? 'Select All' : 'Deselect All'}
        </Button>
      </Box>
      <Divider sx={styles.divider} />
      <List>
        {editorFunctions && (
          <Menu
            keepMounted
            open={mouseY !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
              mouseY !== null && mouseX !== null
                ? { top: mouseY, left: mouseX }
                : undefined
            }
          >
            <MenuItem onClick={handleDelete}>Delete layer</MenuItem>
          </Menu>
        )}
        {layers.vector_layers.map((layer, index) => (
          <Box key={layer.layerId}>
            <ListItemButton
              selected={editorFunctions && layer.id === layerSelected}
              sx={styles.listItem}
              onContextMenu={(e) => handleClick(e, layer.layerId)}
              style={{ cursor: editorFunctions ? 'context-menu' : 'default' }}
            >
              <ListItemSecondaryAction
                sx={styles.checkboxWrapper}
              >
                <Checkbox
                  color="primary"
                  onChange={() => {
                    dispatch(ToggleProjectLayerAction(order._id, layer.id, fileVersions[order._id]));
                  }}
                  checked={checked && layer.visible}
                  sx={styles.checkbox}
                />
              </ListItemSecondaryAction>
              {editorFunctions && (
                <>
                  {/* Display the list item as a button when
                      - On initial page load  when layerSelected = null or
                      - When edit = false or
                      - If a layer is not a selected layer */}
                  {!edit || !layerSelected || !(layer.id === layerSelected) ? (
                    <Button
                      sx={styles.listItemButton}
                      disableRipple
                      onClick={() => {
                        if (edit) setEdit(false);
                        setLayerName(null);
                        dispatch(SetlayerSelectedAction(layer.id));
                        dispatch(SetOrderSelectedAction(order._id));
                        dispatch(SetEditorModeAction(true));
                      }}
                      onDoubleClick={() => {
                        setLayerName(layer.custom_name ? layer.custom_name : layer.id);
                        setEdit(true);
                      }}
                    >
                      <span style={styles.layerText}>{layer.custom_name ? layer.custom_name : layer.id}</span>
                    </Button>
                  ) : (
                    <Box>
                      {/* Otherwise the list item is an input field */}
                      <InputBase
                        sx={{ '& .MuiInputBase-input': styles.inputField }}
                        defaultValue={layer.custom_name ? layer.custom_name : layer.id}
                        ref={inputRef}
                        autoFocus
                        value={layerName}
                        onChange={(e) => {
                          setLayerName(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === 'Escape') {
                            // check if layer name has changed
                            if (!layerName || layerName.trim() === layer.id) {
                              setEdit(false);
                              setLayerName(null);
                              return;
                            }
                            // check if layer name is duplicate and display the warning dialog
                            for (let i = 0; i < layers.vector_layers.length; i += 1) {
                              if (layers.vector_layers[i].id === layerName || layers.vector_layers[i].custom_name === layerName) {
                                setShowDialog(true);
                                setEdit(false);
                                return;
                              }
                            }
                            // else call the editLayer API
                            dispatch(EditLayerAttributeAction(layer.layerId, siteId, { custom_name: layerName }));
                            e.preventDefault();
                            e.stopPropagation();
                            setLayerName(null);
                            setEdit(false);
                          }
                        }}
                      />
                    </Box>
                  )}
                </>
              )}
              {!editorFunctions && (
                <Typography variant="h5" sx={styles.listItemText}>
                  {layer.id}
                </Typography>
              )}
              <LinePropertiesFlyout layer={layer} siteId={siteId} />
            </ListItemButton>
          </Box>
        ))}
        {expressAIInfo && (
          <Box sx={styles.expressAIInfo}>
            <img style={styles.aiIcon} src={aiIcon} alt="Express AI Upload" />
            <Box sx={styles.aiTextBox}>
              <Typography sx={styles.aiText}>This output is full-AI. A fully QC’d dxf will be uploaded by</Typography>
              <Typography sx={styles.aiTextDate} color="secondary">{`${moment(order.deliveryEstimate).format('LL')}`}</Typography>
            </Box>
          </Box>
        )}
      </List>

      {/* Name already taken dialog */}
      <Dialog sx={styles.dialog} open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogContent>
          <Typography sx={styles.dialogText}>
            {`The name "${layerName}" is already taken. Please choose a different name`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button sx={styles.dialogButton} onClick={() => setShowDialog(false)}>Ok</Button>
        </DialogActions>
      </Dialog>

      {/* New layer dialog */}
      <NewLayerDialog siteId={siteId} showNewLayerDialog={showNewLayerDialog} setNewLayerDialog={setNewLayerDialog}/>
    </Box>
  );
};

export default ProjectLayersList;
